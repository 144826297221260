import { getFormProps, getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from '@remix-run/node';
import { redirect } from '@remix-run/node';
import { Link, useFetcher, useLoaderData, useSearchParams } from '@remix-run/react';
import { AuthorizationError } from 'remix-auth';
import { FormStrategy } from 'remix-auth-form';
import { safeRedirect } from 'remix-utils/safe-redirect';
import { z } from 'zod';
import LoginVerificationEmail from '~/components/emails/login-verification.email.server.tsx';
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx';
import { ErrorList, Field } from '~/components/form/forms.tsx';
import { Spacer } from '~/components/spacer.tsx';
import { Button } from '~/components/ui/button.tsx';
import { StatusButton } from '~/components/ui/status-button.tsx';
import { magicLinkVerificationType } from '~/core/auth.server.ts';
import { tryParseFormData } from '~/utils/form-data.server.ts';
import { buildMeta } from '~/utils/meta.js';
import { invariantResponse } from '~/utils/misc.ts';
import { emailSchema, passwordSchema } from '~/utils/user-validation.ts';
import { authStyles } from './_layout.tsx';
import { magicLinkSearchParams } from './login_.link.tsx';

const loginTypeSchema = z.enum(['password', 'magicLink']);

type LoginType = z.infer<typeof loginTypeSchema>;

const magicLinkFormSchema = z.object({
  loginType: z.literal('magicLink'),
  email: emailSchema,
  redirectTo: z.string().optional(),
});

const passwordFormSchema = z.object({
  loginType: z.literal('password'),
  email: emailSchema,
  password: passwordSchema,
  redirectTo: z.string().optional(),
});

export async function loader({ request, context }: LoaderFunctionArgs) {
  await context.authSession.requireAnonymous();

  const search = new URL(request.url).searchParams;

  const loginTypeParseResult = loginTypeSchema.safeParse(search.get('loginType'));
  const loginType = loginTypeParseResult.success ? loginTypeParseResult.data : 'magicLink';

  const rawRedirectTo = search.get('redirectTo') ?? search.get('from');
  const redirectTo = rawRedirectTo?.startsWith('/') ? rawRedirectTo : '/app';

  const session = await context.authCookieSession.getSession(request.headers.get('cookie'));
  const error = session.get(context.auth.sessionErrorKey);
  let errorMessage: string | null = null;
  if (typeof error?.message === 'string') {
    errorMessage = error.message;
  }
  return json(
    {
      formError: errorMessage,
      loginType,
      redirectTo,
    },
    {
      headers: {
        'Set-Cookie': await context.authCookieSession.commitSession(session),
      },
    },
  );
}

export async function action(args: ActionFunctionArgs) {
  const { request, context } = args;
  const parseResult = await tryParseFormData(request);

  if (!parseResult.success) {
    context.sentry.captureMessage('Form data parsing failed', {
      level: 'error',
      extra: {
        url: request.url,
        method: request.method,
        headers: Object.fromEntries(request.headers.entries()),
        error: parseResult.error,
        text: parseResult.text,
      },
    });

    return json({ status: 'unknown' as const, error: parseResult.error }, { status: 400 });
  }

  const formData = parseResult.data;

  const loginTypeParseResult = loginTypeSchema.safeParse(formData.get('loginType'));

  if (!loginTypeParseResult.success) {
    return json({ status: 'unknown' as const, error: 'Invalid login type' } as const, {
      status: 400,
    });
  }

  if (loginTypeParseResult.data === 'magicLink') {
    return loginWithMagicLinkAction(args, formData);
  }

  return loginWithPasswordAction(args, formData);
}

async function loginWithPasswordAction(args: ActionFunctionArgs, formData: FormData) {
  const { request, context } = args;

  const submission = parseWithZod(formData, {
    schema: passwordFormSchema,
  });
  if (submission.status !== 'success') {
    return json({ status: 'error', submission: submission.reply() } as const, {
      status: 400,
    });
  }

  let sessionId: string | null = null;
  try {
    sessionId = await context.auth.authenticate(FormStrategy.name, request.clone(), {
      throwOnError: true,
      context: {
        ...context,
        formData,
      },
    });
  } catch (error) {
    if (error instanceof AuthorizationError) {
      return json(
        {
          status: 'error',
          submission: submission.reply({
            hideFields: ['password'],
            formErrors: [error.message],
          }),
        } as const,
        { status: 400 },
      );
    }
    throw error;
  }

  const session = await context.db.session.findUnique({
    where: { id: sessionId },
    select: { userId: true, expirationDate: true },
  });
  invariantResponse(session, 'newly created session not found');

  const cookieSession = await context.authCookieSession.getSession(request.headers.get('cookie'));
  cookieSession.set(context.auth.sessionKey, sessionId);
  const { redirectTo } = submission.value;
  const responseInit = {
    headers: {
      'Set-Cookie': await context.authCookieSession.commitSession(cookieSession, {
        expires: session.expirationDate,
      }),
    },
  };
  if (!redirectTo) {
    return json({ status: 'success', submission } as const, responseInit);
  }
  throw redirect(safeRedirect(redirectTo), responseInit);
}

async function loginWithMagicLinkAction(args: ActionFunctionArgs, formData: FormData) {
  const { request, context } = args;

  const submission = parseWithZod(formData, {
    schema: magicLinkFormSchema,
  });
  if (submission.status !== 'success') {
    return json({ status: 'error', submission: submission.reply() } as const, {
      status: 400,
    });
  }

  const user = await context.db.user.findFirst({
    where: {
      email: submission.value.email,
    },
    select: {
      id: true,
      email: true,
      phoneNumber: true,
    },
  });

  if (!user) {
    return json(
      {
        status: 'error',
        submission: submission.reply({
          fieldErrors: {
            email: ['Invalid email'],
          },
        }),
      } as const,
      {
        status: 400,
      },
    );
  }

  const verification = await context.verifications.createVerification({
    type: magicLinkVerificationType,
    target: user.email,
  });

  const loginUrl = new URL(`${context.env.APP_URL}/login/link`);
  loginUrl.searchParams.set(magicLinkSearchParams.email, user.email);

  if (submission.value.redirectTo) {
    loginUrl.searchParams.set(magicLinkSearchParams.redirectTo, submission.value.redirectTo);
  }

  const response = await context.email.sendEmail({
    to: user.email,
    subject: 'Welcome back to hostU!',
    react: <LoginVerificationEmail expiration="24 hours" otp={verification.code} />,
  });

  if (context.env.NODE_ENV === 'development') {
    context.logger.info({
      otpCode: verification.code,
      url: loginUrl.toString(),
    });
  }

  if (response.status === 'success') {
    return redirect(loginUrl.pathname + loginUrl.search);
  }
  return json(
    {
      status: 'error',
      submission: submission.reply({
        formErrors: ['Failed to send email'],
      }),
    } as const,
    { status: 500 },
  );
}

export const meta: MetaFunction = (args) => {
  return buildMeta(args, { title: 'Login | hostU' });
};

export default function LoginPage() {
  const { loginType, formError, redirectTo } = useLoaderData<typeof loader>();

  return (
    <div className="flex flex-col justify-center pt-24">
      <div className="mx-auto w-full max-w-md">
        <div className="text-center">
          <h1 className={authStyles.headingClassName}>Welcome back</h1>
        </div>
        <Spacer size="2xs" />

        {loginType === 'password' ? (
          <LoginWithPasswordForm redirectTo={redirectTo} formError={formError} />
        ) : (
          <LoginWithMagicLinkForm redirectTo={redirectTo} formError={formError} />
        )}

        <div className="mx-auto w-full max-w-md px-8">
          <div className="flex xs:flex-row flex-col items-center justify-center gap-2 pt-6">
            <span className="text-muted-foreground">Don't have an account?</span>
            <Link className="" to="/signup" prefetch="intent">
              Sign up here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const useSetLoginType = (loginType: LoginType) => {
  const [_, setSearchParams] = useSearchParams();
  return () => {
    setSearchParams((prev) => {
      prev.set('loginType', loginType);
      return prev;
    });
  };
};

type LoginWithPasswordFormProps = {
  redirectTo: string;
  formError: string | null;
};
function LoginWithPasswordForm(props: LoginWithPasswordFormProps) {
  const { redirectTo, formError } = props;

  const loginFetcher = useFetcher<typeof action>();

  const [form, fields] = useForm({
    id: 'password-login',
    defaultValue: { redirectTo },
    constraint: getZodConstraint(passwordFormSchema),
    lastResult: loginFetcher.data?.status === 'error' ? loginFetcher.data.submission : null,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: passwordFormSchema });
    },
    shouldRevalidate: 'onBlur',
  });

  const setLoginType = useSetLoginType('magicLink');

  return (
    <loginFetcher.Form method="POST" {...getFormProps(form)} key={form.key}>
      <input hidden name="loginType" value={'password' as LoginType} readOnly />
      <Field
        className="mb-3"
        labelProps={{
          children: 'Email',
          className: 'text-muted-foreground',
        }}
        inputProps={{
          ...getInputProps(fields.email, { type: 'email' }),
          autoFocus: true,
          className: 'lowercase',
          placeholder: 'ava@u.northwestern.edu',
          variant: 'underline',
        }}
        errors={fields.email.errors}
      />

      <Field
        className="mb-3"
        labelProps={{
          children: 'Password',
          className: 'text-muted-foreground',
        }}
        inputProps={{
          ...getInputProps(fields.password, { type: 'password' }),
          autoComplete: 'current-password',
          variant: 'underline',
        }}
        errors={fields.password.errors}
      />

      <div className="flex justify-end pb-4">
        <Link to="/forgot-password" className="font-semibold text-body-xs">
          Forgot password?
        </Link>
      </div>

      <input {...getInputProps(fields.redirectTo, { type: 'hidden' })} type="hidden" />
      <ErrorList errors={[...(form.errors ?? []), formError]} id={form.errorId} />

      <div className="flex flex-col items-center gap-2 pt-3">
        <StatusButton
          className="w-full rounded-full"
          variant="gradient"
          size="lg"
          status={
            loginFetcher.state === 'submitting'
              ? 'pending'
              : loginFetcher.data?.status === 'error'
                ? 'error'
                : 'idle'
          }
          type="submit"
          disabled={loginFetcher.state !== 'idle'}
        >
          Log-in
        </StatusButton>
        <Button
          type="button"
          className="w-full rounded-full"
          variant="ghost"
          size="lg"
          onClick={setLoginType}
        >
          Sign in using email
        </Button>
      </div>
    </loginFetcher.Form>
  );
}

type LoginWithMagicLinkFormProps = {
  redirectTo: string;
  formError: string | null;
};
function LoginWithMagicLinkForm(props: LoginWithMagicLinkFormProps) {
  const { redirectTo, formError } = props;

  const loginFetcher = useFetcher<typeof action>();

  const [form, fields] = useForm({
    id: 'magic-link-login',
    defaultValue: { redirectTo },
    constraint: getZodConstraint(magicLinkFormSchema),
    lastResult: loginFetcher.data?.status === 'error' ? loginFetcher.data.submission : null,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: magicLinkFormSchema });
    },
    shouldRevalidate: 'onBlur',
  });

  const setLoginType = useSetLoginType('password');

  return (
    <loginFetcher.Form method="POST" {...getFormProps(form)} key={form.key}>
      <input hidden name="loginType" value={'magicLink' as LoginType} readOnly />
      <Field
        className="mb-3"
        labelProps={{
          children: 'Email',
          className: 'text-muted-foreground',
        }}
        inputProps={{
          ...getInputProps(fields.email, { type: 'email' }),
          autoFocus: true,
          className: 'lowercase',
          placeholder: 'ava@u.northwestern.edu',
          variant: 'underline',
        }}
        errors={fields.email.errors}
      />

      <input {...getInputProps(fields.redirectTo, { type: 'hidden' })} type="hidden" />
      <ErrorList errors={[...(form.errors ?? []), formError]} id={form.errorId} />

      <div className="flex flex-col items-center gap-2 pt-3">
        <StatusButton
          className="w-full rounded-full"
          variant="gradient"
          size="lg"
          status={
            loginFetcher.state === 'submitting'
              ? 'pending'
              : loginFetcher.data?.status === 'error'
                ? 'error'
                : 'idle'
          }
          type="submit"
          disabled={loginFetcher.state !== 'idle'}
        >
          Sign in using email
        </StatusButton>
        <Button
          type="button"
          className="w-full rounded-full"
          variant="ghost"
          size="lg"
          onClick={setLoginType}
        >
          Sign in using password
        </Button>
      </div>
    </loginFetcher.Form>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
